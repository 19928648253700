import { useEffect, useRef, useState } from 'react';
import { trackEngagement, trackImpression } from './tracking.js';
import { getOptimizedText } from './utils.js';

const HeiTeaser = ({ node, rawTitle }) => {
    const { title, introText, query } = rawTitle;

    useEffect(() => {
        trackImpression(node, {
            title,
            introText,
            query,
        });
    }, []);

    const headlineRef = useRef(null);
    const [textLines, setTextLines] = useState([]);

    useEffect(() => {
        if (!headlineRef.current) {
            return;
        }

        console.log(headlineRef);

        const rafId = requestAnimationFrame(() => {
            if (!headlineRef.current) {
                return;
            }

            const { width } = headlineRef.current.getBoundingClientRect();
            getOptimizedText(title, width).then(({ lines }) => {
                setTextLines(lines);
            });
        });

        return () => cancelAnimationFrame(rafId);
    }, [title, headlineRef]);

    const url = new URL('https://vg.no/hei?utm_source=front-teaser');

    if (query && typeof query === 'string') {
        url.searchParams.set('q', encodeURIComponent(query));
    }
    if (query && typeof query === 'boolean') {
        url.searchParams.set('q', encodeURIComponent(title));
    }

    return (
        <article
            className="article article-width-full no-article-image"
            style={{ borderLeft: 0 }}
        >
            <div className="article-container">
                <a
                    itemprop="url"
                    href={url.toString()}
                    onClick={() =>
                        trackEngagement('Click', {
                            custom: { title, introText },
                        })
                    }
                >
                    <div className="titles">
                        {introText && (
                            <span className="insert-title">{introText}</span>
                        )}
                        <h3
                            className="headline"
                            style={{ fontSize: '1.75rem' }}
                            ref={headlineRef}
                            itemProp="headline"
                        >
                            {textLines?.map((line, index) => (
                                <span
                                    key={index}
                                    className="d-block"
                                    style={{
                                        fontSize: line.cqw
                                            ? `${line.cqw}cqw`
                                            : `${line.fs / 16}rem`,
                                    }}
                                >
                                    {line.content}
                                </span>
                            ))}
                        </h3>
                    </div>
                </a>
            </div>
        </article>
    );
};

export default HeiTeaser;
