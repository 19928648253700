export const getOptimizedText = async (text, width) => {
    const url = 'https://www.vg.no/frimand-api/optimizeText?';
    const params = new URLSearchParams();
    const textSplitted = text.split('\n').map((text) => text.trim());
    for (const text of textSplitted) {
        params.append('text[]', text);
    }

    params.append('width', width.toString());

    const req = await fetch(url + params);
    const res = await req.json();

    return res;
};
