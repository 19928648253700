import { render } from 'preact';
import { getConsent, subscribeToConsent } from '@vgno/utils';
import HeiTeaser from './HeiTeaser.jsx';

const renderWithUnleash = async (props) => {
    const { node, getUnleashClient } = props;

    const unleashClient = await getUnleashClient();
    const unleashToggleName = 'hei-teaser-front';
    const isEnabled = unleashClient.isEnabled(unleashToggleName);

    if (isEnabled) {
        try {
            const variant = unleashClient.getVariant(unleashToggleName);
            console.log('Hei VG Teaser: Got variant', variant);

            const title = JSON.parse(variant.payload.value);
            if (variant.enabled) {
                console.log('Hei VG Teaser: Feature toggle enabled, rendering');
                node.style.display = 'block';
                return render(<HeiTeaser {...props} rawTitle={title} />, node);
            }
        } catch (error) {
            console.error('Hei VG Teaser: Error getting variant', error);
        }
    }
};

// We export a function that receives props, including "props.node"
export default async function initHeiVg(props) {
    console.log('Hei VG Teaser: Initializing');

    // Ensure we have a valid HTMLElement to mount onto
    const { node } = props;
    if (!node || !(node instanceof HTMLElement)) {
        console.error('No valid node found for Hei VG Teaser');
        return;
    }

    const consent = await getConsent('CMP:personalisation');
    let initialized = false;

    if (consent) {
        console.log(
            'Hei VG Teaser: Consent already given, checking feature toggle',
        );

        return await renderWithUnleash(props);
    } else {
        subscribeToConsent('CMP:personalisation', async (consented) => {
            if (consented && !initialized) {
                initialized = true;
                console.log(
                    'Hei VG Teaser: Consent eventually given, checking feature toggle',
                );

                return await renderWithUnleash(props);
            }
        });
    }
}
