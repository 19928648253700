/**
 * Tracks user engagement events.
 * @param {string} action
 * @param {Object} object
 * @returns {void}
 */
export function trackEngagement(action, object) {
    const extraId = [object.storyId, object.id].filter(Boolean).join(':');
    const custom = object.custom;

    const payload = {
        type: 'Engagement',
        action,
        object: {
            type: 'UIElement',
            id: `hei-teaser${extraId ? `:${extraId}` : ''}`,
            name: object.name,
            contentId: object.contentId,
        },
    };

    if (custom) {
        payload.object['spt:custom'] = custom;
    }

    // @ts-ignore
    window.pulse((sdk) => {
        sdk.track('trackerEvent', payload);
    });
}

export const trackImpression = (node, custom) => {
    let hasBeenInView = false;
    let inViewTimeout = null;
    let enterTime = null; // Timestamp when the element enters the viewport

    console.log('Tracking impression');

    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                // If >= 50% of the node is in view
                if (
                    entry.isIntersecting &&
                    !hasBeenInView &&
                    entry.intersectionRatio >= 0.5
                ) {
                    // Since we have multiple thresholds, we need to clear the timeout to
                    // prevent multiple impressions, since the observer can trigger multiple times
                    if (inViewTimeout) {
                        clearTimeout(inViewTimeout);
                    }

                    inViewTimeout = setTimeout(() => {
                        hasBeenInView = true;
                        enterTime = performance.now(); // Record the entry timestamp

                        trackEngagement('View', {
                            name: 'Hei Teaser',
                            contentId: 'chatbot',
                            custom: custom,
                        });
                    }, 1000);
                }
                // If user scrolls away after having been in view
                else if (!entry.isIntersecting && hasBeenInView) {
                    observer.disconnect();

                    const leaveTime = performance.now(); // Get the timestamp when leaving
                    const duration = leaveTime - enterTime; // Calculate the duration in milliseconds

                    trackEngagement('View', {
                        id: 'leave',
                        name: 'Hei Teaser',
                        contentId: 'chatbot',
                        custom: {
                            ...custom,
                            duration: duration / 1000, // Convert to seconds
                        },
                    });
                }

                // Clear the timer if no longer intersecting
                if (!entry.isIntersecting && inViewTimeout) {
                    clearTimeout(inViewTimeout);
                }
            });
        },
        {
            threshold: [0, 0.5, 1],
        },
    );

    observer.observe(node);
};
